<template>
  <div class="widget-chart">
    <div class="widget-chart__canvas">
      <canvas
        :id="id"
        class="chart-canvas"
      />
    </div>

    <div
      :id="legendId"
      class="widget-chart__legend"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { htmlLegendPlugin } from '@/plugins/chartjs/analytics/htmlLegend.plugin';
import {
  chartTypes,
  datasetColorsMap,
  datasetContrastColorsMap,
  widgetTypes
} from '@/config/dashboard/analytics.config';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';
import { numberToKMGT } from '@/helpers/utils/numberToKMGT';

export default {
  name: 'WidgetChart',
  props: {
    id: {
      type: String,
      required: true
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    preview: Boolean,
    withDataLabels: Boolean
  },
  data() {
    return {
      chartInstance: null
    };
  },
  computed: {
    ...mapGetters({
      isColorblindMode: 'isColorblindMode'
    }),
    legendId() {
      return `${this.id}-legend`;
    },
    palette() {
      return datasetColorsMap(this.isColorblindMode);
    },
    contrastColorsMap() {
      return datasetContrastColorsMap(this.isColorblindMode);
    }
  },
  watch: {
    chartData(newVal, oldVal) {
      if (checkEqualObject(newVal, oldVal)) {
        return;
      }

      this.updateChart(newVal);
    },
    isColorblindMode() {
      this.updateChart(this.chartData);
    },
    withDataLabels(value) {
      const datalabels = this.chartInstance.config.options.plugins.datalabels;

      if (datalabels) {
        datalabels.display = value && 'auto';
      }

      this.chartInstance.update();
    }
  },
  mounted() {
    this.createChart(this.chartData);
  },
  destroyed() {
    this.destroyChart();
  },
  methods: {
    destroyChart() {
      this.chartInstance && this.chartInstance.destroy();

      const legendNode = document.getElementById(this.legendId);

      if (legendNode) {
        legendNode.innerHTML = '';
      }
    },
    createChart(chartData) {
      const ctx = document.getElementById(`${this.id}`).getContext('2d');

      this.chartInstance = new Chart(ctx, this.buildChartConfig(chartData));
    },
    updateChart(chartData) {
      this.destroyChart();
      this.createChart(chartData);
    },
    checkIsType(datasets, type) {
      return datasets.some(({ chartType }) => type === chartType);
    },
    getChartType({ widgetType, datasets }) {
      const isStacked = this.checkIsType(datasets, chartTypes.STACKED_BAR);
      const isComparative = widgetType === widgetTypes.COMPARATIVE;

      if (isStacked || isComparative) {
        return chartTypes.BAR;
      }

      return datasets[0]?.chartType ?? chartTypes.BAR;
    },
    getColor({ datasetIndex, chartType, labels }) {
      if (chartType === chartTypes.DONUT) {
        return labels.map((_, index) => this.palette[index]);
      }

      return this.palette[datasetIndex];
    },
    getDatasetStyles(data) {
      const color = this.getColor(data);
      const baseStyles = {
        backgroundColor: color
      };
      const borderStyles = {
        borderColor: color,
        borderWidth: 2
      };
      const lineStyles = {
        ...baseStyles,
        ...borderStyles,
        pointRadius: 2.25,
        pointHoverRadius: 3,
        pointBorderColor: 'white',
        pointBorderWidth: 1.25
      };
      const donutStyles = {
        ...baseStyles,
        ...borderStyles
      };
      const stylesByType = {
        [chartTypes.BAR]: baseStyles,
        [chartTypes.STACKED_BAR]: baseStyles,
        [chartTypes.LINE]: lineStyles,
        [chartTypes.DONUT]: donutStyles
      };

      return stylesByType[data.chartType];
    },
    getYAxisId(index) {
      return index ? `y${index}` : 'y';
    },
    getYAxisTicks() {
      return {
        callback: (val) => numberToKMGT(val, 1, val.toFixed(2))
      };
    },
    getXAxisConfig() {
      return {
        grid: {
          display: false
        }
      };
    },
    getChartDatasets(chartData) {
      const { datasets, multiAxis, widgetType } = chartData;
      const isStacked = this.checkIsType(datasets, chartTypes.STACKED_BAR);
      const isComparative = widgetType === widgetTypes.COMPARATIVE;

      return datasets.map(({ chartType, data, label }, datasetIndex) => {
        return {
          data,
          label,
          type: isStacked ? chartTypes.BAR : chartType,
          ...this.getDatasetStyles({ datasetIndex, chartType, ...chartData }),
          ...(multiAxis && { yAxisID: this.getYAxisId(datasetIndex) }),
          // determine the order for the multi-axis bar chart
          // possible values: LINE = 0 BAR = 1
          ...(isComparative && { order: +(chartType === chartTypes.BAR) })
        };
      });
    },
    getChartDimension({ dimension }) {
      return {
        display: !!dimension,
        text: dimension
      };
    },
    getChartScales({ datasets, multiAxis }) {
      if (!datasets?.length) {
        return {};
      }

      const isStacked = this.checkIsType(datasets, chartTypes.STACKED_BAR);
      const baseDataset = datasets[0];

      if (isStacked) {
        return {
          y: {
            title: this.getChartDimension(baseDataset),
            stacked: true,
            ticks: this.getYAxisTicks()
          },
          x: {
            stacked: true,
            ...this.getXAxisConfig()
          }
        };
      }

      if (multiAxis) {
        return datasets.reduce((scales, dataset, index) => {
          scales[this.getYAxisId(index)] = {
            position: index ? 'right' : 'left',
            title: this.getChartDimension(dataset),
            grid: {
              drawOnChartArea: !index
            },
            ticks: this.getYAxisTicks()
          };

          return scales;
        }, { x: this.getXAxisConfig() });
      }

      const isDonut = this.checkIsType(datasets, chartTypes.DONUT);

      if (isDonut) {
        return {};
      }

      return {
        y: {
          title: this.getChartDimension(baseDataset),
          ticks: this.getYAxisTicks()
        },
        x: this.getXAxisConfig()
      };
    },
    getDataLabels({ datasets }) {
      const isDonut = this.checkIsType(datasets, chartTypes.DONUT);
      const isStackedBar = this.checkIsType(datasets, chartTypes.STACKED_BAR);

      if (!isDonut && !isStackedBar) {
        return null;
      }

      return {
        align: 'center',
        anchor: 'center',
        display: this.withDataLabels && 'auto',
        color: ({ dataset, datasetIndex }) => {
          if (isDonut) {
            return this.contrastColorsMap[dataset.backgroundColor[datasetIndex]];
          }

          return this.contrastColorsMap[dataset.backgroundColor];
        },
        labels: {
          value: {
            font: {
              size: this.preview ? 12 : 8
            }
          }
        },
        formatter(value, ctx) {
          let total = 0;

          if (isDonut) {
            const points = ctx.chart.data.datasets[0].data;

            total = points.reduce((total, datapoint) => total + datapoint, 0);
          } else if (isStackedBar) {
            const points = ctx.chart.data.datasets.map(dataset => dataset.data);

            total = points.reduce((total, datapoint) => total + datapoint[ctx.dataIndex], 0);
          }

          const percentage = value / total * 100;

          return percentage
            ? Math.round(percentage) + '%'
            : null;
        }
      };
    },
    buildChartData(chartData) {
      return {
        labels: chartData.labels,
        datasets: this.getChartDatasets(chartData)
      };
    },
    buildChartOptions(chartData) {
      return {
        type: this.getChartType(chartData),
        options: {
          resizeDelay: 25,
          normalized: true,
          responsive: true,
          maintainAspectRatio: false,
          scales: this.getChartScales(chartData),
          plugins: {
            legend: {
              display: false
            },
            htmlLegend: {
              containerID: this.legendId
            },
            datalabels: this.getDataLabels(chartData)
          }
        }
      };
    },
    buildChartConfig(chartData) {
      return {
        ...this.buildChartOptions(chartData),
        data: this.buildChartData(chartData),
        plugins: [htmlLegendPlugin, ChartDataLabels]
      };
    }
  }
};
</script>

<style lang="scss">
// global styles for the chart legend
@import "@/style/components/dashboard/analytics/widget-chart";
</style>