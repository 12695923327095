<template>
  <SubPageWrapper
    class="dashboard__wrapper"
    width="full"
    relative
  >
    <CreateWidgetModal />
    <WidgetChartModal />
    <AnalyticsFilterModal />

    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="monitoringTabs"
        collapsable
      >
        <template #header>
          {{ $t('Web.Dashboard.Monitoring') }}
        </template>

        <template
          v-if="analyticsTabs.length"
          #nestedList="{ collapsed }"
        >
          <SlTabList
            v-model="tabModel"
            :tabs="analyticsTabs"
            :collapsed="collapsed"
          >
            <template #header>
              {{ $t('Web.Dashboard.Analytics') }}
            </template>
          </SlTabList>
        </template>

        <template
          v-if="isAnalyticsAvailable"
          #append
        >
          <SlButton
            variant="tertiary"
            color="grey"
            @click="handleCreateDashboard"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-16 color-grey-70"
              />
            </template>

            {{ $t('Web.Dashboard.CreateDashboard') }}
          </SlButton>
        </template>
      </SlTabList>
    </template>
    <SlTabContent
      v-for="tab in allTabs"
      :key="tab.value"
      :value="tab.value"
      :tab-value="tabModel"
    >
      <component :is="getTabComponent(tab)" />
    </SlTabContent>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DashboardToolbar from '@/components/Dashboard/Monitoring/DashboardToolbar.vue';
import DashboardTile from '@/components/Dashboard/Monitoring/DashboardTile.vue';
import DashboardSkeleton from '@/components/Skeleton/DashboardSkeleton.vue';
import KPITab from '@/components/Dashboard/Tabs/KPITab.vue';
import AnalyticsTab from '@/components/Dashboard/Tabs/AnalyticsTab.vue';
import CreateWidgetModal from '@/components/Modals/Dashboard/CreateWidgetModal.vue';
import WidgetChartModal from '@/components/Modals/Dashboard/WidgetPreviewModal.vue';
import AnalyticsFilterModal from '@/components/Modals/Dashboard/AnalyticsFilterModal.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { KPIsKey, monitoringTabs } from '@/config/dashboard/dashboard.config';
import modalsId from '@/config/shared/modalsId.config';
import { componentNames } from '@/config/app.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests';

export default {
  name: 'Dashboard',
  components: {
    AnalyticsFilterModal,
    CreateWidgetModal,
    WidgetChartModal,
    DashboardToolbar,
    DashboardTile,
    DashboardSkeleton,
    KPITab,
    AnalyticsTab
  },
  mixins: [modal, access],
  data() {
    return {
      monitoringTabs: monitoringTabs(this)
    };
  },
  computed: {
    ...mapState({
      dashboardsList: state => state.dashboard.analytics.dashboards_list || [],
      tab: state => state.dashboard.tab
    }),
    ...mapGetters({
      existingNames: 'dashboard/analytics/existingNames'
    }),
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    analyticsTabs() {
      return this.dashboardsList.map(({ name, id }) => ({
        label: name,
        value: id
      }));
    },
    allTabs() {
      return [
        ...this.monitoringTabs,
        ...this.analyticsTabs
      ];
    },
    isAnalyticsAvailable() {
      return this.$sl_tabEditable && this.$sl_hasAccess(this.$sl_features.purchasingData) && this.$sl_hasAccess(this.$sl_features.salesData);
    }
  },
  watch: {
    tabModel(value) {
      this.updateRouteParam(value);
    }
  },
  async beforeMount() {
    await sendRequests(componentNames.DASHBOARD);

    this.updateRouteParam(this.tabModel);
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      setTab: 'dashboard/setTab',
      resetState: 'dashboard/resetState',
      createDashboard: 'dashboard/analytics/createDashboard'
    }),
    getTabComponent({ value }) {
      if (value === KPIsKey) {
        return 'KPITab';
      }

      return 'AnalyticsTab';
    },
    updateRouteParam(id) {
      const isIdValid = this.dashboardsList.some(({ id: dashboardId }) => dashboardId === id);

      if (!isIdValid) {
        this.setTab(KPIsKey);
      }

      if (this.$route.params.dashboardId === id) {
        return;
      }

      this.$router.replace({
        params: {
          dashboardId: id
        }
      });
    },
    handleCreateDashboard() {
      this.showModal(modalsId.SL_RENAME_MODAL, {
        validationRules: {
          'sl_not_includes': {
            values: this.existingNames,
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Dashboard.Title')
            })
          }
        },
        title: this.$t('Web.Dashboard.Title'),
        placeholder: this.$t('Web.Dashboard.NamePlaceholder'),
        submitText: this.$t('Web.Dashboard.Create'),
        saveCallback: async(...args) => {
          const id = await this.createDashboard(...args);

          if (id) {
            this.setTab(id);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/dashboard";
</style>
