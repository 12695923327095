<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        :divider="action.toggle"
        select-close
        @select="action.action(widget)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 color-grey-50"
            :data="getIcon(action.icon)"
          />
          <SlCheckbox
            v-if="action.toggle"
            :value="withDataLabels"
            inactive
            toggle
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import modalsId from '@/config/shared/modalsId.config';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';

export default {
  name: 'WidgetActionsDropdown',
  mixins: [modal, fileSaver],
  props: {
    actions: {
      type: Number,
      required: true
    },
    widget: {
      type: Object,
      required: true
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    withDataLabels: Boolean
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Dashboard.Widget.Edit'),
          icon: 'pen',
          action: this.handleEditWidget
        },
        {
          flag: 1,
          label: this.$t('Web.Dashboard.Widget.Duplicate'),
          icon: 'copy',
          action: this.handleDuplicateWidget
        },
        {
          flag: 3,
          label: this.$t('Web.Dashboard.Widget.ExportToXLSX'),
          icon: 'download',
          action: this.handleExportToXLSX
        },
        {
          flag: 2,
          label: this.$t('Web.Dashboard.Widget.Delete'),
          icon: 'delete',
          action: this.handleDeleteWidget
        },
        {
          flag: 4,
          toggle: true,
          label: this.$t('Web.Dashboard.Widget.ShowPercent'),
          action: this.handleTogglePercent
        }
      ]
    };
  },
  inject: ['setLoading'],
  computed: {
    ...mapGetters({
      activeDashboard: 'dashboard/analytics/activeDashboard',
      uiSettings: 'user/currentUiSettings'
    }),
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      duplicateWidget: 'dashboard/analytics/duplicateWidget',
      deleteWidget: 'dashboard/analytics/deleteWidget',
      exportWidgetToXlsx: 'dashboard/analytics/exportWidgetToXlsx',
      toggleDataLabels: 'dashboard/analytics/toggleDataLabels'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    handleEditWidget({ id: widgetId }) {
      this.showModal(modalsId.CREATE_DASHBOARD_WIDGET, {
        dashboardId: this.activeDashboard.id,
        widgetId
      });
    },
    async handleDuplicateWidget({ id: widgetId }) {
      try {
        this.setLoading(true);

        await this.duplicateWidget({
          dashboardId: this.activeDashboard.id,
          widgetId
        });
      } finally {
        this.setLoading(false);
      }
    },
    async handleDeleteWidget({ id: widgetId }) {
      try {
        this.setLoading(true);

        await this.deleteWidget({
          dashboardId: this.activeDashboard.id,
          widgetId,
          sectionIndex: this.sectionIndex
        });
      } finally {
        this.setLoading(false);
      }
    },
    handleExportToXLSX({ id: widgetId, data }) {
      this.saveFile(
        this.exportWidgetToXlsx.bind(this, {
          dashboardId: this.activeDashboard.id,
          widgetId
        })
      );

      ampli.analyticsWidgetExported({
        exportFormat: exportTypes.XLSX,
        widgetDataType: data.widgetType
      });
    },
    handleTogglePercent() {
      this.toggleDataLabels({
        widgetId: this.widget.id,
        sectionIndex: this.sectionIndex,
        withDataLabels: !this.withDataLabels
      });
    }
  }
};
</script>
