<template>
  <div class="widget-table-wrapper">
    <div class="widget-table">
      <SlTable
        :id="id"
        header-unique-key="key"
        header-name-key="name"
        unique-key="id"
        :rows="tableData.body"
        :headers="tableData.header"
        :hidden-columns-keys="['id', 'enabled']"
        :value-parser="parseValue"
        :max-height="1000"
        :row-height="40"
        :col-min-width="130"
        :col-resize="false"
        list
        col-fill
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetTable',
  props: {
    id: {
      type: String,
      required: true
    },
    tableData: {
      type: Object,
      required: true
    }
  },
  methods: {
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val ?? cellValue.auto ?? '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/dashboard/analytics/widget-table.scss';
</style>