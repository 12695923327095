<template>
  <SlModal
    :id="id"
    :width="1200"
    :title="title"
    :loading="isLoading || isInitializing"
    :updating="isUpdating"
    :close-callback="handleCancel"
    persistent
    maximizable
    @on-enter="handleCreate"
    @created="onCreated"
    @hide="onHide"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content"
    >
      <FilterBuilder :filter-id="filterId" />
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="!isEditable"
          @click="handleCreate"
        >
          {{ createLabel }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FilterBuilder from '@/components/Filter/FilterBuilder.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ReportFilterModal',
  components: {
    FilterBuilder
  },
  mixins: [modal, access],
  data() {
    return {
      id: modalsId.ANALYTICS_FILTER,
      filterId: null,
      saveCallback: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      filter: state => state.filter.filter,
      isEditable: state => state.filter.filter?.editable,
      isUpdating: state => state.filter.is_updating,
      isInitializing: state => state.filter.is_initializing
    }),
    title() {
      return this.isEdit
        ? this.$t('Web.Filters.EditFilter')
        : this.$t('Web.Filters.CreateFilter');
    },
    createLabel() {
      return this.isEdit
        ? this.$t('Common.Save')
        : this.$t('Web.Modals.BtnCreate');
    },
    isEdit() {
      return this.filterId !== null;
    }
  },
  methods: {
    ...mapActions({
      changeFilterCache: 'filter/changeFilterCache',
      saveFilterCache: 'filter/saveFilterCache',
      discardFilterCache: 'filter/discardFilterCache'
    }),
    onHide() {
      this.filterId = null;
      this.saveCallback = null;
      this.isLoading = false;
    },
    async handleCreate() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;

        const id = await this.saveFilterCache();

        await this.saveCallback && this.saveCallback(id);
      } finally {
        this.isLoading = false;

        this.hideModal(this.id);
      }
    },
    handleCancel() {
      this.discardFilterCache();

      this.hideModal(this.id);
    }
  }
};
</script>
