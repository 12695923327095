<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="secondary"
        color="grey"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey-50"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(dashboard)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="getIcon(action.icon)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { KPIsKey } from '@/config/dashboard/dashboard.config';

export default {
  name: 'DashboardActionsDropdown',
  mixins: [modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    dashboard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Dashboard.Rename'),
          icon: 'pen',
          action: this.openRenameModal
        },
        {
          flag: 1,
          label: this.$t('Web.Dashboard.Delete'),
          icon: 'delete',
          action: this.handleDeleteDashboard
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      existingNames: 'dashboard/analytics/existingNames'
    }),
    allowedActions() {
      return this.actionsConfig.filter(action => {
        return action.visible || this.actions & 1 << action.flag;
      });
    }
  },
  methods: {
    ...mapActions({
      renameDashboard: 'dashboard/analytics/renameDashboard',
      deleteDashboard: 'dashboard/analytics/deleteDashboard',
      setTab: 'dashboard/setTab'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    openRenameModal({ id, name }) {
      this.showModal(modalsId.SL_RENAME_MODAL, {
        text: name,
        validationRules: {
          'sl_not_includes': {
            values: this.existingNames,
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Dashboard.Title')
            })
          }
        },
        title: this.$t('Web.Dashboard.RenameDashboard'),
        saveCallback: (name) => this.renameDashboard({
          id,
          name
        })
      });
    },
    handleDeleteDashboard(dashboard) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', { 1: this.dashboard.name }),
        text: this.$t('Web.Modals.Delete.Description'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: async() => {
          await this.deleteDashboard(dashboard);

          this.setTab(KPIsKey);
        }
      });
    }
  }
};
</script>
