const getInvalidFields = (observer) => {
  if (!observer) {
    return [];
  }

  return observer.$children.filter(child => child?.$refs?.provider?.errors?.length);
};

export const scrollToInvalidField = (observer) => {
  const invalidField = getInvalidFields(observer)[0];

  if (!invalidField) {
    return;
  }

  invalidField.$el.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest'
  });
};