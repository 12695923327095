// https://www.chartjs.org/docs/3.9.1/samples/legend/html.html

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('chart-legend');

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const { type } = chart.config;
    const isPieOrDoughnut = type === 'pie' || type === 'doughnut';
    const isSingleDataset = chart.data.datasets.length === 1;

    if (!isPieOrDoughnut && isSingleDataset) {
      return;
    }

    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement('li');

      li.classList.add('chart-legend__item');
      li.setAttribute('title', item.text);

      li.onclick = () => {
        if (isPieOrDoughnut) {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const colorBox = document.createElement('span');

      colorBox.style.background = item.fillStyle;
      colorBox.classList.add('chart-legend__color-box');

      // Text
      const textContainer = document.createElement('p');

      textContainer.classList.add('chart-legend__text');
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);

      textContainer.appendChild(text);

      li.appendChild(colorBox);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};