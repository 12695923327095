<template>
  <SlModal
    :id="id"
    :title="widgetName"
    fullscreen
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <WidgetTable
        v-if="isTable"
        :id="componentId"
        :table-data="widgetData"
      />
      <WidgetChart
        v-else
        :id="componentId"
        :chart-data="widgetData"
        :with-data-labels="withDataLabels"
        preview
      />
    </div>
  </SlModal>
</template>

<script>
import WidgetChart from '@/components/Dashboard/Analytics/WidgetChart.vue';
import WidgetTable from '@/components/Dashboard/Analytics/WidgetTable.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'WidgetPreviewModal',
  components: {
    WidgetChart,
    WidgetTable
  },
  mixins: [modal],
  data() {
    return {
      id: modalsId.WIDGET_CHART_MODAL,
      widgetName: null,
      widgetId: null,
      widgetData: null,
      withDataLabels: false
    };
  },
  computed: {
    componentId() {
      return `${this.widgetId}-modal`;
    },
    isTable() {
      return this.widgetData?.header?.length;
    }
  },
  methods: {
    onModalHide() {
      this.widgetName = null;
      this.widgetId = null;
      this.widgetData = null;
      this.withDataLabels = false;
    }
  }
};
</script>
