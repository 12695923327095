<template>
  <ContentBlock>
    <div
      v-resize-observe="onResize"
      class="kpi-tab"
    >
      <SlOverlay
        :show="isLoading"
        full-loader
        filled
      >
        <DashboardSkeleton />
      </SlOverlay>

      <DashboardToolbar
        v-model="selectedTileFormat"
        :tile-formats="tileFormats"
      />
      <div
        class="kpi-tab__cards"
        :class="selectedTileFormat"
      >
        <DashboardTile
          v-for="dashboard in dashboards"
          :key="dashboard.title"
          :title="dashboard.tileTitle"
          :count="dashboard.tileValue"
          :get-compared-index="dashboard.getComparedIndex"
          :plan-items="dashboard.planItems"
          :period="dashboard.period"
          :chart-data="dashboard.bar"
          :unit="dashboard.unit"
          :currency="dashboard.currency"
          :type="dashboard.type"
          :less-is-better="dashboard.lessIsBetter"
        />
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardToolbar from '@/components/Dashboard/Monitoring/DashboardToolbar.vue';
import DashboardTile from '@/components/Dashboard/Monitoring/DashboardTile.vue';
import DashboardSkeleton from '@/components/Skeleton/DashboardSkeleton.vue';
import { mediaQueries, tileFormats } from '@/config/dashboard/dashboard.config';

export default {
  name: 'KPITab',
  components: {
    DashboardSkeleton,
    DashboardTile,
    DashboardToolbar
  },
  data() {
    return {
      isLoading: false,
      selectedTileFormat: tileFormats.GRID_04,
      tileFormats: [
        {
          value: tileFormats.GRID_01,
          hidden: false
        },
        {
          value: tileFormats.GRID_02,
          hidden: false
        },
        {
          value: tileFormats.GRID_03,
          hidden: false
        },
        {
          value: tileFormats.GRID_04,
          hidden: false
        }
      ]
    };
  },
  computed: {
    ...mapState({
      dashboardTiles: (state) => state.dashboard.monitoring.tiles
    }),
    dashboards() {
      return this.dashboardTiles.filter((tile) => tile.visible);
    }
  },
  async beforeMount() {
    try {
      if (this.dashboardTiles.length) {
        return;
      }

      this.isLoading = true;

      await this.fetchDashboard();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchDashboard: 'dashboard/monitoring/fetchDashboard'
    }),
    shouldSwitchFormat(format) {
      const formatIndex = this.tileFormats.findIndex((el) => el.value === format);
      const currentFormatIndex = this.tileFormats.findIndex((el) => el.value === this.selectedTileFormat);

      return formatIndex < currentFormatIndex;
    },
    onResize(el) {
      const contentWidth = el.offsetWidth;

      switch (true) {
        case (contentWidth > mediaQueries.MAX):
          if (this.shouldSwitchFormat(tileFormats.GRID_04)) {
            this.selectedTileFormat = tileFormats.GRID_04;
          }

          this.tileFormats[3].hidden = false;
          this.tileFormats[2].hidden = false;
          this.tileFormats[1].hidden = false;
          this.tileFormats[0].hidden = false;
          break;
        case (contentWidth >= mediaQueries.MD && contentWidth <= mediaQueries.MAX):
          if (this.shouldSwitchFormat(tileFormats.GRID_03)) {
            this.selectedTileFormat = tileFormats.GRID_03;
          }

          this.tileFormats[3].hidden = true;
          this.tileFormats[2].hidden = false;
          this.tileFormats[1].hidden = false;
          this.tileFormats[0].hidden = false;
          break;
        case (contentWidth >= mediaQueries.MIN && contentWidth < mediaQueries.MD):
          if (this.shouldSwitchFormat(tileFormats.GRID_02)) {
            this.selectedTileFormat = tileFormats.GRID_02;
          }

          this.tileFormats[3].hidden = true;
          this.tileFormats[2].hidden = true;
          this.tileFormats[1].hidden = false;
          this.tileFormats[0].hidden = false;
          break;
        case (contentWidth < mediaQueries.MIN):
          if (this.shouldSwitchFormat(tileFormats.GRID_01)) {
            this.selectedTileFormat = tileFormats.GRID_01;
          }

          this.tileFormats[3].hidden = true;
          this.tileFormats[2].hidden = true;
          this.tileFormats[1].hidden = true;
          this.tileFormats[0].hidden = false;
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/dashboard/monitoring/kpi-tab";
</style>