<template>
  <div
    :class="['analytics-widget__wrapper', {
      'analytics-widget__wrapper--disabled': disabled
    }]"
    :style="{
      width: `${width}%`
    }"
  >
    <SlOverlay :show="isLoading" />

    <div class="analytics-widget">
      <div class="analytics-widget__header">
        <div class="analytics-widget__drag-zone">
          <icon
            class="fill-off size-20 color-grey-60 analytics-widget__drag-icon"
            data="@icons/drag_and_drop.svg"
          />
          <div class="analytics-widget__title">
            {{ widget.name }}
          </div>
        </div>
        <div class="analytics-widget__actions">
          <SlButton
            v-if="isFullscreenAvailable"
            v-tooltip="getTooltip($t('Web.Dashboard.Widget.Fullscreen'))"
            variant="tertiary"
            color="grey"
            size="xs"
            icon
            @click="openFullscreenChart"
          >
            <icon
              data="@icons/fullscreen.svg"
              class="fill-off size-16 color-grey-60"
            />
          </SlButton>
          <WidgetActionsDropdown
            v-if="!disabled"
            :widget="widget"
            :actions="widget.actions"
            :section-index="sectionIndex"
            :with-data-labels="withDataLabels"
          />
        </div>
      </div>
      <div class="analytics-widget__content">
        <SlErrorBoundary>
          <template v-if="isContentExist">
            <div
              v-if="isSingleNumber"
              class="analytics-widget__single-number"
            >
              <span v-tooltip="getTooltip(widget.data.value)">
                {{ widget.data.value }}
              </span>
            </div>
            <WidgetTable
              v-else-if="isTable"
              :id="widget.id"
              :table-data="widget.data"
            />
            <WidgetChart
              v-else
              :id="widget.id"
              :chart-data="widget.data"
              :with-data-labels="withDataLabels"
            />
          </template>
          <SlNoData v-else>
            <template #title>
              {{ $t('Web.NoData.NoAvailableData') }}
            </template>
          </SlNoData>
        </SlErrorBoundary>
      </div>
    </div>
    <slot name="separator" />
  </div>
</template>

<script>
import WidgetActionsDropdown from '@/components/Dashboard/Analytics/WidgetActionsDropdown.vue';
import WidgetChart from '@/components/Dashboard/Analytics/WidgetChart.vue';
import WidgetTable from '@/components/Dashboard/Analytics/WidgetTable.vue';
import { modal } from '@/mixins/modal';
import { widgetTypes } from '@/config/dashboard/analytics.config';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'WidgetItem',
  components: {
    WidgetTable,
    WidgetChart,
    WidgetActionsDropdown
  },
  mixins: [modal],
  props: {
    widget: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    disabled: Boolean,
    withDataLabels: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  provide() {
    return {
      setLoading: this.setLoading
    };
  },
  computed: {
    isSingleNumber() {
      return this.widget.data?.widgetType === widgetTypes.TOTAL_VALUE;
    },
    isTable() {
      return this.widget.data?.header?.length;
    },
    isFullscreenAvailable() {
      return this.widget.data && !this.isSingleNumber;
    },
    isContentExist() {
      if (!this.widget.data) {
        return false;
      }

      const { value, labels, datasets, body } = this.widget.data;

      if (datasets?.length) {
        return labels?.length;
      }

      return value || datasets?.length || body?.length;

    }
  },
  methods: {
    getTooltip,
    setLoading(value) {
      this.isLoading = value;
    },
    openFullscreenChart() {
      this.showModal(modalsId.WIDGET_CHART_MODAL, {
        widgetName: this.widget.name,
        widgetId: this.widget.id,
        widgetData: this.widget.data,
        withDataLabels: this.withDataLabels
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/dashboard/analytics/widget-item.scss';
</style>
