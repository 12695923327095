<template>
  <div
    class="analytics-separator"
    :class="[ `analytics-separator--${type}`, {
      'analytics-separator--active': isActive,
      'analytics-separator--disabled': disabled
    }]"
  >
    <div
      class="analytics-separator__line"
      @mousedown="handleMouseDown"
    />
  </div>
</template>

<script>
export default {
  name: 'ElementSeparator',
  props: {
    type: {
      type: String,
      default: 'col',
      validator: (value) => ['col', 'row'].includes(value)
    },
    resizableSelector: {
      type: String,
      default: null
    },
    resizableSiblingSelector: {
      type: String,
      default: null
    },
    disabled: Boolean
  },
  data() {
    return {
      isActive: false,
      isResized: false,
      startX: 0,
      startY: 0,
      startWidthPx: 0,
      startHeightPx: 0,
      startWidthPc: 0,
      startHeightPc: 0,
      endWidthPc: 0,
      endHeightPc: 0,
      startSiblingWidthPc: 0,
      endSiblingWidthPc: 0,
      MIN_ROW_HEIGHT_PC: 15,
      MIN_COL_WIDTH_PC: 10
    };
  },
  computed: {
    isCol() {
      return this.type === 'col' && this.resizableSiblingSelector;
    },
    activeBodyClass() {
      return `resize-${this.type}--active`;
    }
  },
  methods: {
    getPercentNum(value) {
      return +value.replace('%', '');
    },
    getResizedParams() {
      if (this.isCol) {
        return {
          width: this.endWidthPc,
          siblingWidth: this.endSiblingWidthPc
        };
      }

      return {
        height: this.endHeightPc
      };
    },
    toggleActiveState() {
      document.body.classList.toggle(this.activeBodyClass);
      this.isActive = !this.isActive;
    },
    setStartValues(e, node, siblingNode) {
      this.startWidthPx = node.clientWidth;
      this.startHeightPx = node.clientHeight;
      this.startWidthPc = this.getPercentNum(node.style.width);
      this.startHeightPc = this.getPercentNum(node.style.height);
      this.startX = e.clientX;
      this.startY = e.clientY;

      if (this.isCol) {
        this.startSiblingWidthPc = this.getPercentNum(siblingNode.style.width);
      }
    },
    formatPercent(value) {
      return +value.toFixed(2);
    },
    calculatePercent(newValue, oldValue, oldPercent) {
      return this.formatPercent((newValue * oldPercent) / oldValue);
    },
    resizeCol(e, node, siblingNode) {
      const dx = e.clientX - this.startX;
      const currentWidth = this.startWidthPx + dx;
      const endWidthPc = this.calculatePercent(currentWidth, this.startWidthPx, this.startWidthPc);
      const endSiblingWidthPc = this.formatPercent(this.startSiblingWidthPc + (this.startWidthPc - endWidthPc));

      if (endWidthPc < this.MIN_COL_WIDTH_PC || endSiblingWidthPc < this.MIN_COL_WIDTH_PC) {
        return;
      }

      this.endWidthPc = endWidthPc;
      this.endSiblingWidthPc = endSiblingWidthPc;

      node.style.width = `${this.endWidthPc}%`;
      siblingNode.style.width = `${this.endSiblingWidthPc}%`;
    },
    resizeRow(e, node) {
      const dy = e.clientY - this.startY;
      const currentHeight = this.startHeightPx + dy;
      const endHeightPc = this.calculatePercent(currentHeight, this.startHeightPx, this.startHeightPc);

      if (endHeightPc < this.MIN_ROW_HEIGHT_PC) {
        return;
      }

      this.endHeightPc = endHeightPc;

      node.style.height = `${this.endHeightPc}%`;
    },
    handleMouseDown(e) {
      const resizableNode = this.resizableSelector && document.querySelector(this.resizableSelector);
      const resizableSiblingNode = this.resizableSiblingSelector && document.querySelector(this.resizableSiblingSelector);

      if (!resizableNode) {
        return;
      }

      this.toggleActiveState();
      this.setStartValues(e, resizableNode, resizableSiblingNode);

      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    handleMouseMove(e) {
      this.isResized = true;

      const resizableNode = document.querySelector(this.resizableSelector);

      if (this.isCol) {
        const resizableSiblingNode = document.querySelector(this.resizableSiblingSelector);

        this.resizeCol(e, resizableNode, resizableSiblingNode);
      } else {
        this.resizeRow(e, resizableNode);
      }
    },
    handleMouseUp() {
      this.toggleActiveState();

      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);

      if (this.isResized) {
        this.$emit('resized', this.getResizedParams());

        this.isResized = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/dashboard/analytics/element-separator.scss';
</style>