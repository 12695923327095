var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize-observe",rawName:"v-resize-observe",value:(_vm.onResize),expression:"onResize"}],staticClass:"tile__wrapper"},[_c('div',{staticClass:"tile__title",class:{
      'tile__title--wrap': _vm.tileWidth < _vm.tileMinWidth
    }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
        content: _vm.tileTitle,
        placement: 'bottom'
      })),expression:"getTooltip({\n        content: tileTitle,\n        placement: 'bottom'\n      })"}],staticClass:"tile__title-content",on:{"click":_vm.handleTileLinkClick}},[_vm._v(" "+_vm._s(_vm.tileTitle)+" ")]),_c('div',{staticClass:"tile__period"},[_c('SlDropdown',{staticClass:"sl-multiple-select",attrs:{"target-label":_vm.selectedPeriod,"auto-size":"","placement":"bottom-end"},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.periodKeys),function(option){return _c('SlDropdownOption',{key:option.value,attrs:{"select-close":""},on:{"select":function($event){return _vm.changeSelect(option.value)}}},[_vm._v(" "+_vm._s(option.text)+" ")])})},proxy:true}])})],1)]),_c('div',{staticClass:"tile__content"},[_c('div',{staticClass:"tile__line-wrapper"},[_c('div',{staticClass:"tile__count"},[_vm._v(" "+_vm._s(_vm.cardCount + _vm.unit)+" ")]),(_vm.isBadgeActive)?_c('SlBadge',{staticClass:"tile__compared-data-wrapper",attrs:{"variant":_vm.badgeColor}},[_c('icon',{staticClass:"tile__arrow size-16",class:[
            ("tile__arrow_" + _vm.badgeColor),
            ("tile__arrow--" + _vm.arrowDirection)
          ],attrs:{"data":require("@icon/dashboard_arrow.svg")}}),_c('div',{staticClass:"tile__compared-data"},[_vm._v(" "+_vm._s(_vm.comparedValue)+" ")])],1):_vm._e()],1),_c('div',{staticClass:"tile__planning-items"},[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Descriptions.Items'))+" "+_vm._s(_vm.planItems)+" ")]),_c('DashboardChart',{attrs:{"chart-data":_vm.chartData,"active-date":_vm.activeDate,"get-compared-index":_vm.getComparedIndex},on:{"change-date":_vm.compareData,"change-date-to-default":_vm.changeDefaultData}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }