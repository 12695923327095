<template>
  <div class="tile__bar">
    <div
      ref="chartTile"
      class="bar"
    >
      <div class="bar__lines">
        <i
          v-for="el of GRID_LINES"
          :key="el"
          class="bar__line"
        />
      </div>

      <div
        v-for="(el, i) of chartData"
        :key="i + '_' + el.value"
        class="bar__item-wrapper"
        :style="{
          padding: barPadding
        }"
        @mouseleave="handleMouseEvent(i, false, el)"
        @mouseover="handleMouseEvent(i, true, el)"
      >
        <div
          class="bar__item"
          :class="{
            'bar__item--green': isGreen(el, i),
            'bar__item--red': isRed(el, i)
          }"
          :style="{
            height: getBarHeight(el.percOfMax),
            background: getBarBackground(el, i)
          }"
        />
      </div>
    </div>
    <div
      v-if="componentIsMounted"
      class="bar__dates"
    >
      <div
        v-for="(el, i) of barDates"
        :key="i + '_' + el"
        class="bar__date"
        :style="{
          left: getLeftOffset(i)
        }"
      >
        <div v-if="i === hoverIndex">
          {{ el }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { chartColors } from '@/config/dashboard/dashboard.config';

export default {
  name: 'DashboardChart',
  props: {
    chartData: {
      type: Array,
      required: true
    },
    getComparedIndex: {
      type: Function,
      required: true
    },
    activeDate: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      GRID_LINES: 6,
      INITIAL_BAR_HEIGHT: 144,
      LABEL_MIN_WIDTH: 36,
      hoverIndex: this.activeDate,
      componentIsMounted: false
    };
  },
  computed: {
    ...mapGetters({
      barDates: 'dashboard/monitoring/barDates',
      isColorblindMode: 'isColorblindMode'
    }),
    ...mapState({
      periodsNumber: (state) => state.dashboard.monitoring.periodsNumber
    }),
    colors() {
      return {
        [chartColors.red]: this.isColorblindMode ? 'rgb(213, 94, 0)' : '#f04438',
        [chartColors.green]: this.isColorblindMode ? 'rgb(240, 228, 66)' : '#3ccb64',
        blue: this.isColorblindMode ? 'rgb( 86, 180, 233)' : '#d1e0ff',
        activeBlue: this.isColorblindMode ? 'rgb(86, 114, 179)' : '#528bff'
      };
    },
    isBarsHovered() {
      return this.chartData.some((el) => el.hovered);
    },
    currentDisplayValue() {
      return this.chartData[this.hoverIndex].displayData.value;
    },
    barPadding() {
      return `0 ${this.f(this.periodsNumber)}px`;
    }
  },
  watch: {
    activeDate: {
      handler() {
        this.handleHoverIndex();
      }
    }
  },
  mounted() {
    this.componentIsMounted = true;
  },
  methods: {
    handleMouseEvent(i, isOver, value) {
      if (!this.chartData[i].empty) {
        this.hoverIndex = i;
        this.chartData[this.hoverIndex].hovered = isOver;

        if (isOver) {
          this.$nextTick(() => {
            this.$emit('change-date', value);
          });
        } else {
          this.$emit('change-date-to-default', value);
          this.handleHoverIndex();
        }
      }
    },
    getBarHeight(value) {
      const height = value * this.INITIAL_BAR_HEIGHT;

      return (height < 2 ? 2 : height) + 'px';
    },
    getLeftOffset(index) {
      const chartTileWidth = this.$refs.chartTile.clientWidth;
      const barWidth = (1 / this.periodsNumber) * chartTileWidth;
      const initialLeft = barWidth * (index + 0.5);

      if (initialLeft - this.LABEL_MIN_WIDTH < 0) {
        return 0;
      }

      if (initialLeft + this.LABEL_MIN_WIDTH > chartTileWidth) {
        return chartTileWidth - 2 * this.LABEL_MIN_WIDTH + 'px';
      }

      return initialLeft - this.LABEL_MIN_WIDTH + 'px';
    },
    isGreen(el, i) {
      return (this.hoverIndex === i) && (el.displayData.arrowColor === chartColors.green);
    },
    isRed(el, i) {
      return (this.hoverIndex === i) && (el.displayData.arrowColor === chartColors.red);
    },
    getBarBackground(el, i) {
      if (el.empty) {
        return '';
      }

      if (
        this.getComparedIndex(this.hoverIndex) !== this.hoverIndex
        && this.currentDisplayValue === '—'
      ) {
        return this.colors.blue;
      }

      if (
        this.currentDisplayValue !== '—'
        && this.getComparedIndex(this.hoverIndex) === i
      ) {
        return this.colors.activeBlue;
      }

      if (!this.isBarsHovered && this.hoverIndex === i) {
        return this.colors[el.displayData.arrowColor] || this.colors.blue;
      }

      if (el.hovered) {
        return this.colors[el.displayData.arrowColor] || this.colors.blue;
      }

      return this.colors.blue;
    },
    handleHoverIndex() {
      this.hoverIndex = this.activeDate;
    },
    f(x) {
      return -1 * Math.log(x) + 5.4;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/dashboard/monitoring/bar";
</style>
