<template>
  <div class="dashboard-skeleton">
    <div class="dashboard-skeleton__header">
      <div class="dashboard-skeleton__header-row">
        <v-skeleton-loader
          type="heading"
          width="20%"
        />
        <v-skeleton-loader
          type="heading"
          width="30%"
        />
      </div>
      <div class="dashboard-skeleton__header-row">
        <v-skeleton-loader
          type="heading"
          width="30%"
        />
        <v-skeleton-loader
          type="heading"
          width="45%"
        />
      </div>
    </div>
    <div class="dashboard-skeleton__tiles">
      <div
        v-for="item in tiles"
        :key="item"
        class="dashboard-skeleton__tile"
      >
        <v-skeleton-loader
          type="heading"
        />
        <v-skeleton-loader
          type="text"
          width="40%"
        />
        <v-skeleton-loader
          type="text"
        />
        <v-skeleton-loader
          type="image"
          max-height="115px"
        />
        <v-skeleton-loader
          type="text"
          width="50%"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardSkeleton',
  data() {
    return {
      tileCount: 8
    };
  },
  computed: {
    tiles() {
      return Array(this.tileCount).fill(0).map((_, index) => index);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/dashboard-skeleton.scss";
</style>
