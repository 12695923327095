<template>
  <div
    class="analytics-section__wrapper"
    :data-section-id="index"
    :style="{
      height: `${section.height}%`
    }"
  >
    <draggable
      v-model="section.widgets"
      item-key="id"
      :move="handleMove"
      v-bind="dragOptions"
      :disabled="disabled"
      class="analytics-section"
      @start="handleDragStart"
      @end="handleDragEnd"
    >
      <template v-for="(widget, widgetIndex) in section.widgets">
        <WidgetItem
          v-if="widgets[widget.id]"
          :key="widget.id"
          :data-widget-id="widget.id"
          :width="widget.width"
          :widget="widgets[widget.id]"
          :section-index="index"
          :disabled="disabled"
          :with-data-labels="!!widget.withDataLabels"
        >
          <template #separator>
            <ElementSeparator
              :key="widget.id"
              :resizable-selector="getWidgetSelector(widgetIndex)"
              :resizable-sibling-selector="getWidgetSelector(widgetIndex + 1)"
              :disabled="isWidgetSeparatorDisabled(widgetIndex)"
              @resized="(data) => resizeGrid({
                ...data,
                sectionIndex: index,
                widgetIndex,
                widgetSiblingIndex: widgetIndex + 1
              })"
            />
          </template>
        </WidgetItem>
      </template>
    </draggable>
    <ElementSeparator
      v-if="section.widgets.length"
      type="row"
      :resizable-selector="`[data-section-id='${index}']`"
      :disabled="disabled"
      @resized="(data) => resizeGrid({
        ...data,
        sectionIndex: index
      })"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetItem from '@/components/Dashboard/Analytics/WidgetItem.vue';
import ElementSeparator from '@/components/Dashboard/Analytics/ElementSeparator.vue';
import { MAX_WIDGETS_PER_ROW } from '@/config/dashboard/analytics.config';

export default {
  name: 'SectionItem',
  components: {
    ElementSeparator,
    WidgetItem
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    widgets: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      dragOptions: {
        group: 'widgets',
        animation: 200,
        forceFallback: true,
        removeCloneOnHide: false,
        ghostClass: 'analytics-widget--ghost',
        chosenClass: 'analytics-widget--chosen',
        dragClass: 'analytics-widget--drag',
        handle: '.analytics-widget__drag-zone',
        draggable: '.analytics-widget__wrapper',
        dataIdAttr: 'data-widget-id'
      }
    };
  },
  methods: {
    ...mapActions({
      repositionGrid: 'dashboard/analytics/repositionGrid',
      resizeGrid: 'dashboard/analytics/resizeGrid'
    }),
    getWidgetSelector(widgetIndex) {
      const widget = this.section.widgets[widgetIndex];

      if (!widget) {
        return '';
      }

      return `[data-widget-id='${widget.id}']`;
    },
    isWidgetSeparatorDisabled(widgetIndex) {
      return this.disabled || widgetIndex === this.section.widgets.length - 1;
    },
    handleMove({ draggedContext, relatedContext }) {
      const isSameSection = relatedContext.list.some(({ id }) => id === draggedContext.element.id);
      const isMaxWidgets = relatedContext.list.length >= MAX_WIDGETS_PER_ROW;

      return isSameSection || !isMaxWidgets;
    },
    toggleCursor() {
      document.body?.classList.toggle('dragging');
    },
    cloneChart(event) {
      const itemCanvas = event.item.querySelector('.chart-canvas');
      const cloneCanvas = document
        .querySelector('.analytics-widget--drag')
        .querySelector('.chart-canvas');

      if (!itemCanvas || !cloneCanvas) {
        return;
      }

      const cloneCanvasContext = cloneCanvas.getContext('2d');

      cloneCanvasContext.drawImage(itemCanvas, 0, 0);
    },
    handleDragStart(e) {
      this.cloneChart(e);
      this.toggleCursor();

      // reset lib dragged node opacity
      const dragNode = document.querySelector('.analytics-widget--drag');

      if (dragNode) {
        dragNode.style.opacity = 1;
      }
    },
    handleDragEnd() {
      this.toggleCursor();

      this.repositionGrid();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/dashboard/analytics/section-item.scss';
</style>